@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./font-definitions.css";

@layer base {
  html {
    @apply scroll-smooth;
  }
  body {
    @apply overflow-x-hidden  bg-light-beige font-ppmori text-gray-warm-900;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  #CookiebotWidget {
    display: none !important;
  }

  #CybotCookiebotDialogPoweredbyImage {
    content: url("../public/logo_520_520.png");
  }
}

@layer components {
  .main-header {
    @apply bg-sand transition-colors duration-500 ease-in-out;
  }
  .main-header-dark {
    @apply bg-light-sand;
  }
  .main-header .sub-header .sub-header-wrapper {
    @apply bg-light-sand transition-colors duration-500 ease-in-out;
  }
  .main-header-dark .sub-header .sub-header-wrapper {
    @apply bg-sand;
  }

  .cat-active {
    @apply underline decoration-1 underline-offset-[6px] md:underline-offset-[8px];
  }

  .cta-btn {
    @apply inline-flex h-10 w-auto max-w-[40px] items-center rounded-full text-black transition-all duration-500 ease-in-out;
  }

  .cta-btn:hover {
    @apply max-w-[300px];
  }

  .cta-btn-full {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 20px 32px;
    border-radius: 50px;
    transition:
      color 0.33s linear 0.1s,
      border-color ease-out 0.5s,
      background-color 0.33s linear 0s;
    white-space: pre;
    z-index: 1;
  }

  .cta-btn-full::after {
    border-radius: 50%;
    content: "";
    height: 350px;
    min-width: 380px;
    position: absolute;
    top: 0;
    transform: scale(1) translateY(70px);
    transition: transform 0.5s cubic-bezier(0.66, 0, 0.34, 1);
    width: 100%;
    z-index: -1;
  }

  .cta-btn-full:hover {
    @apply text-black;
  }

  .cta-btn-full:hover:after {
    transform: translateY(-120px);
    -webkit-transform: translateY(-120px);
    -moz-transform: translateY(-120px);
    -ms-transform: translateY(-120px);
    -o-transform: translateY(-120px);
  }

  .slider-btn {
    position: relative;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    overflow: hidden;
    border-radius: 100%;
    background-color: white;
    transition: background-color 0.33s linear 0s;
  }

  .slider-btn:after {
    border-radius: 100%;
    content: "";
    height: 70px;
    min-width: 70px;
    position: absolute;
    top: 0;
    transform: scale(100%) translateY(67px);
    transition: transform 0.5s cubic-bezier(0.66, 0, 0.34, 1);
    width: 100%;
    z-index: -1;
  }

  @media (hover: hover) {
    .slider-btn:hover:after {
      @apply -translate-y-[10px];
    }
  }

  .arrow > path {
    z-index: 30;
    transition: all 0.33s linear 0.1s;
  }

  @media (hover: hover) {
    .slider-btn:hover .arrow > path {
      @apply stroke-white;
    }
  }

  .silder-thumbnail {
    @apply transition-all duration-500 ease-in-out;
  }

  .newsletter .terms a {
    @apply font-semibold text-dark-charcoal/100 underline;
  }

  .logo-btn {
    @apply mx-3 cursor-pointer rounded-full bg-dark-beige px-[60px] py-6 transition-all duration-300 ease-in-out hover:bg-cta-orange lg:mx-4;
  }

  .logo-btn-img {
    @apply object-contain transition-all duration-300 ease-in-out;
  }

  .logo-btn:hover .logo-btn-img {
    @apply brightness-0 invert;
  }

  .article-card {
    border-radius: 12px;
  }

  .article-card-btn {
    @apply transition-all duration-500 ease-in-out;
  }

  .article-card-heading {
    @apply transition-all duration-300 ease-out;
  }

  .article-card-illustration {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
      skew(0deg, 0deg);
    transform-style: preserve-3d;
    @apply transition-all duration-500 ease-in-out;
  }

  .article-card-img {
    transform: scale(1);
    @apply transition-all duration-500 ease-in-out;
  }

  @media (hover: hover) {
    .article-card:hover .article-card-illustration {
      transform: scale3d(1.15, 1.15, 1) rotateZ(-7deg);
    }

    .article-card:hover .article-card-img {
      transform: scale(1.1);
    }

    .article-card:hover .article-card-btn {
      @apply min-w-[45px];
    }
  }

  .mega-menu-item::after {
    content: "";
    @apply absolute -right-3 bottom-[5px] block h-[6px] w-[6px] rotate-45 border-r border-t border-gray-warm-900 transition-colors duration-300 ease-in-out;
  }
  .mega-menu-item:hover::after {
    @apply border-cta-orange;
  }
}

@layer utilities {
  .wrapper {
    @apply mx-auto max-w-[1512px] px-4 lg:px-20;
  }

  .section-padding-lg {
    @apply py-32;
  }

  .section-padding-m {
    @apply py-20;
  }

  .section-padding-s {
    @apply py-16;
  }

  .section-padding-m-lg {
    @apply pb-32 pt-20;
  }

  .section-padding-lg-m {
    @apply pb-20 pt-32;
  }

  .h4-clamp {
    font-size: clamp(30px, 3vw, 3rem);
    line-height: clamp(38px, 3vw, 3.75rem);
  }

  .card-heading {
    font-size: clamp(1.2rem, 0.81rem + 1.6641vw, 1.875rem);
    line-height: 1.2;
  }

  .card-tagline {
    font-size: clamp(1rem, 0.9422rem + 0.2465vw, 1.1rem);
  }

  @media screen and (min-width: 1024px) {
    .card-heading {
      font-size: clamp(1.2rem, -0.2164rem + 2.2131vw, 1.875rem);
      line-height: 1.2;
    }

    .card-tagline {
      font-size: clamp(1rem, 0.5803rem + 0.6557vw, 1.1rem);
    }
  }

  .job-article-content {
    @apply prose-img:img-prose prose prose-h2:mb-4 prose-h2:mt-16 prose-h2:text-display-xs prose-h2:font-semibold prose-h3:mb-4 prose-h3:mt-8 prose-h3:text-display-xs prose-h3:font-semibold prose-h4:text-display-xs prose-h4:font-semibold prose-p:mb-[18px] prose-p:text-body-lg prose-a:text-cta-orange prose-li:marker:text-gray-warm-900 prose-h2:lg:text-display-md prose-h3:lg:text-display-sm;
  }

  .article-content {
    @apply prose-ul:ul-prose prose-li:li-prose prose-img:img-prose prose prose-h2:mb-5 prose-h2:mt-0 prose-h2:text-display-xs prose-h2:font-semibold prose-h3:mb-4 prose-h3:mt-8 prose-h3:text-display-xs prose-h3:font-semibold prose-h4:text-display-xs prose-h4:font-semibold prose-p:mb-[18px] prose-p:text-body-lg prose-h2:lg:text-display-md prose-h3:lg:text-display-sm;
  }

  .article-content .wp-caption {
    @apply my-12 !w-full;
  }

  .article-content .wp-caption-text {
    @apply mt-0 text-body-sm;
  }

  .img-prose {
    @apply mb-4 mt-0 w-full rounded-xl;
  }

  .cta-content {
    @apply prose text-white prose-a:text-white prose-a:underline;
  }

  .ul-prose {
    background-color: #f7f0e9;
    border-radius: 16px;
    padding: 32px;
    list-style-position: outside;
    text-align: left;
    display: inline-block;
  }

  .li-prose {
    @apply mb-8 ml-5 last-of-type:mb-0;
  }

  .li-prose::marker {
    color: black;
  }

  .footer-group-heading {
    @apply py-2 text-body-xl font-semibold lg:mb-4 lg:py-0;
  }

  .footer-list {
    @apply space-y-3 pb-6 pt-4 lg:space-y-4 lg:pb-0 lg:pt-0;
  }

  .footer-link {
    @apply text-body-lg transition-colors duration-300 ease-out hover:text-cta-orange;
  }

  .form-checkbox {
    @apply relative h-4 w-4 cursor-pointer appearance-none rounded-full outline-none before:absolute before:left-[5px] before:top-[2px] before:h-[8px] before:w-[5px] before:rotate-45 before:border-b-2 before:border-r-2 before:border-transparent before:transition checked:before:border-dark-blue focus:ring-0 focus:ring-offset-0;
  }

  .noscroll {
    @apply overflow-hidden;
  }

  .hyphens {
    hyphens: auto;
  }

  .service-article {
    max-height: 350px;
    transition: max-height 0.8s ease-out;
  }

  .service-article.expanded {
    max-height: 2000px;
  }

  .lds-ripple {
    animation-delay: -0.5s;
  }

  .span-3 {
    transform: translateY(-5px);
  }
}
